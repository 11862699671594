import { FILTER_DROPDOWN_COUNT_HIDDEN_VALUE } from "components/FilterDropdown/FilterDropdown.d";
import React from "react";
import classnames from "classnames";
import range from "lodash/range";
import { FormCheckedStatePositionConfiguration, Key } from "./KeyConfigurationList.d";
import { AutomationMode, FormPositionConfiguration } from "../types";
import {
  FilterOption,
  FilterOptionType,
  KeyListWithPositionConfigurationQuery,
  PositionConfigurationInput,
} from "../../../pacts/app-webcore/hasura-webcore.graphql";
import { SettingIcon } from "../../../components/Icons";
import { formatTemp, isAutomate24h } from "../key.helper";
import { formatTime24h } from "../../../utils/date";

export const fixedFilterOptions: FilterOption[] = [
  {
    field: "isDeviatedFromDefaultConfig",
    value: "1",
    label: "Settings Overridden",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "Automation Settings",
  },
  {
    field: "automationModes",
    value: AutomationMode.SuperSave,
    label: "SuperSave",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "Automation Mode",
  },
  {
    field: "automationModes",
    value: AutomationMode.SmartSave,
    label: "SmartSave",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "Automation Mode",
  },
  {
    field: "automationModes",
    value: AutomationMode.Disabled,
    label: "Disabled",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "Automation Mode",
  },
  {
    field: "setpointLimitMin",
    value: "",
    options: range(16, 31).map((val) => formatTemp(val)),
    label: "Min",
    type: FilterOptionType.Select,
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "Setpoint Limits",
  },
  {
    field: "setpointLimitMax",
    value: "",
    options: range(16, 31).map((val) => formatTemp(val)),
    label: "Max",
    type: FilterOptionType.Select,
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "Setpoint Limits",
  },
  {
    field: "automationHourStart",
    value: "",
    label: "Start",
    type: FilterOptionType.TimePicker,
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "Automation Hours",
  },
  {
    field: "automationHourStop",
    value: "",
    label: "Stop",
    type: FilterOptionType.TimePicker,
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "Automation Hours",
  },
];

export const transformKeys = (data: KeyListWithPositionConfigurationQuery): Key[] => {
  return (data?.locationOne?.searchPositions || [])
    .map((k) => ({
      ...k,
      isDeviatedFromDefaultConfig:
        k.searchRooms && !!k.searchRooms.find((r) => r.positionConfiguration[0]?.isDeviatedFromDefaultConfig === true),
      type: "key",
      rooms: k.searchRooms
        ? k.searchRooms.map((r) => {
            return {
              ...r,
              type: "room",
              parentId: k.positionId,
              automationMode: r.positionConfiguration[0]?.automationMode,
              unoccupiedTemp: r.positionConfiguration[0]?.unoccupiedTemp,
              heatingUnoccupiedTemp: r.positionConfiguration[0]?.heatingUnoccupiedTemp,
              minTemp: r.positionConfiguration[0]?.minTemp,
              maxTemp: r.positionConfiguration[0]?.maxTemp,
              hideSetpointLimit: r.positionConfiguration[0]?.hideSetpoint,
              acNightStart: r.positionConfiguration[0]?.acNightStart,
              acNightEnd: r.positionConfiguration[0]?.acNightEnd,
              isDeviatedFromDefaultConfig: r.positionConfiguration[0]?.isDeviatedFromDefaultConfig,
              operationalMode: r.positionConfiguration[0]?.operationalMode,
            };
          })
        : [],
    }))
    .filter((k) => k.searchRooms && k.searchRooms?.length > 0);
};

export const getIsDeviatedFromDefaultIcon = (val: any, className: string = "px-m") => {
  if (val === true) {
    return <SettingIcon className={classnames(className, "text-warning")} tooltip="Settings Overridden" />;
  }
  return <SettingIcon className={classnames(className, "text-gray")} tooltip="Default Settings" />;
};

export const getValueForSubmit = (
  positionId: string,
  formPosConfig: FormPositionConfiguration & FormCheckedStatePositionConfiguration
) => {
  const positionConfigs: PositionConfigurationInput = { positionId };
  positionConfigs.automationMode = formPosConfig.automationModeChecked ? formPosConfig.automationMode : undefined;
  positionConfigs.unoccupiedTemp = formPosConfig.unoccupiedTempChecked ? formPosConfig.unoccupiedTemp : undefined;
  positionConfigs.hideSetpoint = formPosConfig.hideSetpointChecked ? formPosConfig.hideSetpoint : undefined;

  positionConfigs.heatingUnoccupiedTemp = formPosConfig.heatingUnoccupiedTempChecked
    ? formPosConfig.heatingUnoccupiedTemp
    : undefined;

  positionConfigs.heatingHideSetpoint = formPosConfig.heatingHideSetpointChecked
    ? formPosConfig.heatingHideSetpoint
    : undefined;

  positionConfigs.occTimeoutDayMins = formPosConfig.occTimeoutDayMinsChecked
    ? formPosConfig.occTimeoutDayMins
    : undefined;

  positionConfigs.occTimeoutNightMins = formPosConfig.occTimeoutNightMinsChecked
    ? formPosConfig.occTimeoutNightMins
    : undefined;

  positionConfigs.freezeProtectionTemp = formPosConfig.freezeProtectionTempChecked
    ? formPosConfig.freezeProtectionTemp
    : undefined;

  positionConfigs.actuationTimeoutSeconds = formPosConfig.actuationTimeoutSecondsChecked
    ? formPosConfig.actuationTimeoutSeconds
    : undefined;

  positionConfigs.operationalMode = formPosConfig.operationalModeChecked ? formPosConfig.operationalMode : undefined;

  positionConfigs.enableDoorSensor = formPosConfig.doorSensorAutomationChecked
    ? formPosConfig.enableDoorSensor
    : undefined;

  positionConfigs.expiredAt = formPosConfig.expiredAt ? formPosConfig.expiredAt : undefined;
  positionConfigs.doorSensorTimeoutMins = formPosConfig.doorSensorTimeoutMins
    ? formPosConfig.doorSensorTimeoutMins
    : undefined;

  if (formPosConfig.setpointChecked) {
    positionConfigs.minTemp = formPosConfig.minTemp;
    positionConfigs.maxTemp = formPosConfig.maxTemp;
  }

  if (formPosConfig.heatingSetpointChecked) {
    positionConfigs.heatingMinTemp = formPosConfig.heatingMinTemp;
    positionConfigs.heatingMaxTemp = formPosConfig.heatingMaxTemp;
  }

  if (
    formPosConfig.automationHoursChecked &&
    formPosConfig.automationHours &&
    formPosConfig.automationHours.length === 2
  ) {
    positionConfigs.acNightStart = formatTime24h(formPosConfig.automationHours[1]);
    positionConfigs.acNightEnd = formatTime24h(formPosConfig.automationHours[0]);
  }

  if (formPosConfig.activeHoursChecked) {
    positionConfigs.occNightStart = formatTime24h(formPosConfig.activeHours![1]);
    positionConfigs.occNightEnd = formatTime24h(formPosConfig.activeHours![0]);
  }

  return positionConfigs;
};

export const ConfigurationToolTip = {
  AutomationMode: (
    <div>
      <span className="font-weight-bold">SuperSave</span>: Switch off HVAC when unoccupied.
      <br />
      <span className="font-weight-bold">SmartSave</span>: Set HVAC to pre-set temperature when unoccupied.
      <br />
      <span className="font-weight-bold">Disabled</span>: Turn off HVAC automation.
      <br />
    </div>
  ),
  UnoccupiedSetpoint: (
    <div>
      <span className="font-weight-bold">Unoccupied Setpoint</span>: Adjust the HVAC temperature when the room is
      unoccupied.
    </div>
  ),
  HeatingUnoccupiedSetpoint: (
    <div>
      <span className="font-weight-bold">Heating Unoccupied Setpoint</span>: Adjust the HVAC temperature when the room
      is unoccupied in heating mode.
    </div>
  ),
  SetpointLimits: (
    <div>
      <span className="font-weight-bold">Setpoint Limits</span>: Adjust the lowest and highest temperatures the AC can
      be set to.
    </div>
  ),
  HeatingSetpointLimits: (
    <div>
      <span className="font-weight-bold">Heating Setpoint Limits</span>: Adjust the lowest and highest temperatures the
      AC can be set to in heating mode.
    </div>
  ),
  AutomationHours: (
    <div>
      <span className="font-weight-bold">Automation Hours</span>: Adjust the hours when automation starts and ends.
      <br />
      <span className="font-weight-bold">24 hours</span>: Automation works all day and all night, this increases savings
      but the AC might switch off at night.
      <br />
      <span className="font-weight-bold">Next Day</span>: Indicates that automation continues past midnight and ends the
      next day.
    </div>
  ),
  ActiveHours: (
    <div>
      <span className="font-weight-bold">Active Hours</span>: Adjust the hours when your guests are most likely to be
      awake and active.
    </div>
  ),
  OccupancyTimeoutDuringActiveHours: (
    <div>
      <span className="font-weight-bold">Occupancy Timeout During Active Hours</span>: Adjust duration of inactivity for
      sensor to consider the room unoccupied when your guests are most likely to be awake and active.
    </div>
  ),
  OccupancyTimeoutOutsideActiveHours: (
    <div>
      <span className="font-weight-bold">Occupancy Timeout Outside Active Hours</span>: Adjust duration of inactivity
      for sensor to consider the room unoccupied when your guests are most likely asleep.
    </div>
  ),
  FreezeProtectionTemp: <div />,
  ActuationTimeoutSeconds: <div />,

  OperationalMode: (
    <div>
      Set whether your building is currently supplying heating or cooling to your rooms. This configuration is only
      relevant if you are operating a 2 pipe HVAC system with heating and cooling switchover.
    </div>
  ),

  DoorSensorTimeoutMins: (
    <div>
      Set the number of minutes a door needs to stay open before we report it as open. This setting is used to prevent
      short open and close actions from triggering unwanted automation.
    </div>
  ),
  DoorSensorAutomation: <div>Only disables open door automation</div>,
  ForceOccupancyAutomation: (
    <div>
      <span className="font-weight-bold">Force Occupancy Automation</span>: Disregards the last HVAC power settings and
      forces it to turn on if an occupancy is detected.
    </div>
  ),
};

export const getAutomationHoursText = (record: Key) => {
  if (record.type !== "room") return "";
  if (isAutomate24h(record.acNightStart, record.acNightEnd)) return "24 hours";
  if (record.acNightEnd && record.acNightStart) return `${record.acNightEnd} - ${record.acNightStart}`;
  return "";
};
