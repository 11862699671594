import React, { useEffect, useMemo, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { InstallationModeProvider } from "contexts/InstallationMode/installation-mode-context";
import { MenuProvider } from "contexts/menu-context";
import { RoleProvider } from "contexts/role-context";
import { useUserWithAuthTokenLazyQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import getRules, { ROLE, UserWithAuthToken } from "rbac-rules";
import { hotjar } from "react-hotjar";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import useRedirectToCurrentInstallation from "hooks/use-redirect-to-current-installation";
import { ApiProvider, useApiContext } from "contexts/api-context";

// Plugins for chartjs
import "chartjs-plugin-zoom";

// services
import { URL_CONFIG } from "config";
import { Auth0Provider, useAuth0 } from "services/auth/authService";
import logger from "../services/logger/logger";

// global ui styles
import "antd/dist/antd.less";
import "styles/global.scss";

// components
import ErrorBoundary from "../components/ErrorBoundary/ErrorBoundary";
import Loading from "../components/Loading";
import Routes from "./Routes";
import PublicRoutes from "./PublicRoutes";

const IntercomBoot: React.FC<{ userWithAuthToken?: UserWithAuthToken }> = ({ userWithAuthToken }) => {
  const { boot } = useIntercom();

  useEffect(() => {
    if (userWithAuthToken) {
      boot({
        email: userWithAuthToken.email,
        userId: userWithAuthToken.userId,
        name: userWithAuthToken.name,
      });
    }
  }, [userWithAuthToken, boot]);

  return null;
};

const RoutesWithProviders: React.FC = () => {
  const { setAccessTokenAndAuthType, accessToken } = useApiContext();
  const { isAuthenticated, getAccessTokenSilently, user, logout } = useAuth0();
  const [userWithAuthToken, setUserWithAuthToken] = useState<UserWithAuthToken>();
  const [intercomAppId, setIntercomAppId] = useState<string | null>(null);

  const { redirectToInstallationPage } = useRedirectToCurrentInstallation({ userWithAuthToken });

  const [userWithAuthTokenQuery, { loading: userWithAuthTokenLoading }] = useUserWithAuthTokenLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.userWithAuthToken) {
        setUserWithAuthToken(data.userWithAuthToken);
      }
    },
    onError: () => {
      logger.error("Could not get user with auth token");
      logout({ returnTo: URL_CONFIG.AFTER_LOGOUT_RETURN_TO });
    },
  });

  useEffect(() => {
    if (accessToken) userWithAuthTokenQuery();
  }, [accessToken, userWithAuthTokenQuery]);

  useEffect(() => {
    if (user) {
      logger.identify(user.sub, user.nickname, user.name);
    }
  }, [isAuthenticated, user]);

  const roleValue = useMemo(() => {
    return getRules(userWithAuthToken);
  }, [userWithAuthToken]);

  const shouldEnableIntercom = useMemo(() => {
    const appId =
      (roleValue?.isDev ? process.env.REACT_APP_INTERCOM_APP_ID_DEV : process.env.REACT_APP_INTERCOM_APP_ID) || "";
    setIntercomAppId(appId);
    return (roleValue && [ROLE.CUSTOMER, ROLE.INSTALLER].includes(roleValue.roleName)) || roleValue?.isDev;
  }, [roleValue]);

  useEffect(() => {
    if (userWithAuthToken) {
      redirectToInstallationPage();
    }
  }, [roleValue, userWithAuthToken, redirectToInstallationPage]);

  useEffect(() => {
    async function getAccessTokenAndStoreInState() {
      const token = await getAccessTokenSilently();
      setAccessTokenAndAuthType?.(token);
    }
    if (isAuthenticated && setAccessTokenAndAuthType) {
      getAccessTokenAndStoreInState();
    }
  }, [isAuthenticated, getAccessTokenSilently, setAccessTokenAndAuthType]);

  return userWithAuthTokenLoading || !intercomAppId ? (
    <Loading />
  ) : (
    <IntercomProvider appId={intercomAppId}>
      {shouldEnableIntercom && <IntercomBoot userWithAuthToken={userWithAuthToken} />}
      <RoleProvider value={roleValue}>
        <MenuProvider>
          <InstallationModeProvider>
            <Routes />
          </InstallationModeProvider>
        </MenuProvider>
      </RoleProvider>
    </IntercomProvider>
  );
};

const App: React.FC = () => {
  useEffect(() => {
    logger.info("App initialised, Version:", process.env.REACT_APP_VERSION);

    hotjar.initialize(parseInt(process.env.REACT_APP_HOTJAR_ID!), parseInt(process.env.REACT_APP_HOTJAR_SV!));
  }, []);

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <ApiProvider>
          <Switch>
            <Route path="/public">
              <PublicRoutes />
            </Route>
            <Route>
              <Auth0Provider
                domain={process.env.REACT_APP_AUTH0_DOMAIN!}
                clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
                audience={process.env.REACT_APP_AUTH0_AUDIENCE}
                scope="openid"
                redirectUri={window.location.origin}
              >
                <RoutesWithProviders />
              </Auth0Provider>
            </Route>
          </Switch>
        </ApiProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default App;
