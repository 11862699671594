import { Col, Collapse, Row } from "antd";
import errorHandler from "errorHandler";
import useBreakpoint from "hooks/use-breakpoint";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { AvailableOperationalModes } from "utils/locationMetadata";
import {
  NodeSubType,
  PositionConfigurations,
  PositionConfigurationsByKeyQuery,
  useAutomationModeDisabledSubscription,
  useGetShowHeatingModeQuery,
  useKeyQuery,
  usePositionConfigurationSubscription,
  usePositionConfigurationsByKeyQuery,
} from "../../../../pacts/app-webcore/hasura-webcore.graphql";
import { getCurrentOrDefaultPositionConfig } from "../../key.helper";
import { AutomationMode, PositionConfigurationRecordType } from "../../types";
import { RoomContainer, RoomSlots } from "./KeyConfiguration.d";
import RoomComponent from "./Room";

const { Panel } = Collapse;

const KeyConfiguration = () => {
  const { keyId, locationId } = useParams<{ keyId: string; locationId: string }>();
  const [rooms, setRooms] = useState<RoomContainer[]>([]);
  const [showHeatingMode, setShowHeatingMode] = useState<boolean>(false);
  const [isRunningInstallationMode, setIsRunningInstallationMode] = useState<boolean>(false);
  const [isAutomationModeDisabled, setAutomationModeDisabled] = useState<boolean>(false);
  const [availableOperationalModes, setAvailableOperationalModes] = useState<AvailableOperationalModes>(
    AvailableOperationalModes.Both
  );
  const [roomSlots, setRoomSlots] = useState<RoomSlots[]>([]);
  const screen = useBreakpoint();

  const fromConfiguration = (room: RoomContainer, configuration: PositionConfigurations | null) => {
    const doorSensorAutomation =
      configuration?.enableDoorSensor === null || configuration?.enableDoorSensor === undefined
        ? true
        : configuration.enableDoorSensor;
    // hide setpoint by default for rooms that are not deviated from default config
    return {
      ...room,
      automationMode: configuration?.automationMode ?? AutomationMode.Disabled,
      hideSetpoint: configuration?.hideSetpoint ?? true,
      heatingHideSetpoint: configuration?.heatingHideSetpoint ?? true,
      minTemp: configuration?.minTemp,
      maxTemp: configuration?.maxTemp,
      heatingMinTemp: configuration?.heatingMinTemp,
      heatingMaxTemp: configuration?.heatingMaxTemp,
      occTimeoutDayMins: configuration?.occTimeoutDayMins,
      occTimeoutNightMins: configuration?.occTimeoutNightMins,
      doorSensorTimeoutMins: configuration?.doorSensorTimeoutMins,
      occNightStart: configuration?.occNightStart,
      occNightEnd: configuration?.occNightEnd,
      acNightEnd: configuration?.acNightEnd,
      acNightStart: configuration?.acNightStart,
      modifiedByUser: configuration?.modifiedByUser,
      modifiedAt: configuration?.creationDate,
      unoccupiedTemp: configuration?.unoccupiedTemp,
      heatingUnoccupiedTemp: configuration?.heatingUnoccupiedTemp,
      freezeProtectionTemp: configuration?.freezeProtectionTemp,
      actuationTimeoutSeconds: configuration?.actuationTimeoutSeconds,
      specialInstallationMode: configuration?.specialInstalltionMode,
      operationalMode: configuration?.operationalMode,
      actingMode: configuration?.actingMode,
      expiredAt: configuration?.expiredAt,
      isDeviatedFromDefaultConfig: !!configuration?.isDeviatedFromDefaultConfig,
      enableDoorSensor: doorSensorAutomation,
      forceOccupancyAutomation: configuration?.forceOccupancyAutomation,
    };
  };

  useEffect(() => {
    setRooms([]);
  }, [keyId]);

  useKeyQuery({
    variables: {
      keyId,
    },
    onCompleted: ({ key: data }) => {
      const slots = data.rooms.map((room) => {
        let isMappingWithPFC: boolean = false;
        if (room.slots) {
          room.slots.forEach((slot) => {
            const mappedNode = slot.currentMappedNode?.mappedNode;
            if (mappedNode) {
              switch (mappedNode.subType) {
                case NodeSubType.Aircon_2pfc:
                case NodeSubType.Aircon_2pfcAicool:
                case NodeSubType.Aircon_2pfcPlc:
                case NodeSubType.Aircon_4pfc:
                  isMappingWithPFC = true;
                  break;
                default:
              }
            }
            switch (slot.nodeSubType) {
              case NodeSubType.Aircon_2pfc:
              case NodeSubType.Aircon_2pfcAicool:
              case NodeSubType.Aircon_2pfcPlc:
              case NodeSubType.Aircon_4pfc:
                isMappingWithPFC = true;
                break;
              default:
            }
          });
        }
        return {
          roomId: room.roomId,
          isMappingWithPFC,
        };
      });
      setRoomSlots(slots);
    },
  });

  const bindingData = (data: PositionConfigurationsByKeyQuery) => {
    const roomData = data.position!.rooms.map((r): RoomContainer => {
      const configuration = getCurrentOrDefaultPositionConfig(r.positionConfiguration as any[]);
      const defaultConfiguration =
        (r.positionConfiguration.find(
          (pc) => pc.recordType === PositionConfigurationRecordType.DEFAULT
        ) as PositionConfigurations) ?? null;
      const config = fromConfiguration(r, configuration);
      const defaultConfig = fromConfiguration(r, defaultConfiguration);
      setIsRunningInstallationMode(config.specialInstallationMode);
      const slot = roomSlots.find((room) => room.roomId === r.positionId);

      return {
        config,
        defaultConfig,
        isDeviatedFromDefaultConfig: configuration?.isDeviatedFromDefaultConfig === true,
        isMappingWithPFC: slot?.isMappingWithPFC ?? false,
      };
    });
    setRooms(roomData);
  };

  const { refetch } = usePositionConfigurationsByKeyQuery({
    variables: {
      keyId,
    },
    onCompleted: (data: PositionConfigurationsByKeyQuery) => {
      bindingData(data);
    },
    onError: errorHandler.handleError,
  });

  const reloadKey = async () => {
    const { data } = await refetch({
      keyId,
    });
    if (data?.position) {
      bindingData(data);
    }
  };

  useEffect(() => {
    reloadKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomSlots]);

  useGetShowHeatingModeQuery({
    variables: {
      locationId,
    },
    onCompleted: (data) => {
      if (data.locationMetadata?.showHeatingMode) {
        setShowHeatingMode(data.locationMetadata.showHeatingMode);
      }
      setAvailableOperationalModes(data.locationMetadata?.availableOperationalModes as AvailableOperationalModes);
    },
  });

  usePositionConfigurationSubscription({
    variables: {
      keyId,
    },
    onSubscriptionData: (data) => {
      if (data.subscriptionData.data?.position) {
        const configData: PositionConfigurationsByKeyQuery = {
          position: data.subscriptionData.data.position,
        };
        bindingData(configData);
      }
    },
  });

  useAutomationModeDisabledSubscription({
    variables: {
      locationId,
    },
    onSubscriptionData: (data) => {
      setAutomationModeDisabled(data.subscriptionData.data?.locationMetadata?.isAutomationModeDisabled ?? false);
    },
  });

  const defaultActiveRoomIds = useMemo(() => {
    if (screen.mobileAndTabletOnly && rooms?.length > 1) {
      return [];
    }
    // if desktop then open all rooms by default
    return rooms.map(({ config }) => config.positionId);
  }, [rooms, screen]);

  return (
    <div className="mb-l">
      <Row>
        <Col span={24}>
          {rooms.length > 0 && (
            <>
              {isAutomationModeDisabled && (
                <div className="pb-l ml-xs">
                  <span className="text-warning">
                    Automation has been disabled, please enable it to edit configurations.
                  </span>
                </div>
              )}
              <Collapse defaultActiveKey={defaultActiveRoomIds}>
                {rooms.map((room) => (
                  <Panel
                    header={room.config.positionName}
                    key={room.config.positionId}
                    className="no-padding-collapsible-tab"
                  >
                    <RoomComponent
                      room={room}
                      reloadRooms={reloadKey}
                      showHeatingMode={showHeatingMode}
                      isRunningInstallationMode={isRunningInstallationMode}
                      isAutomationModeDisabled={isAutomationModeDisabled}
                      availableOperationalModes={availableOperationalModes}
                    />
                  </Panel>
                ))}
              </Collapse>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default KeyConfiguration;
