import { Button, Col, Form, message, Row, Select, Switch } from "antd";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { get, range } from "lodash";
import moment, { Moment } from "moment";
import classnames from "classnames";
import isNil from "lodash/isNil";
import { useParams } from "react-router-dom";
import { PreCoolStatus } from "pages/Key/CustomerKeyList/KeyList.d";
import {
  AutoModeIcon,
  CoolIcon,
  DoorCloseIcon,
  DryIcon,
  FanIcon,
  PowerOffIcon,
  PowerOnIcon,
  PreCoolIcon,
  SunIcon,
  TemperatureIcon,
  UserIcon,
} from "../../../../components/Icons";
import useBreakpoint from "../../../../hooks/use-breakpoint";
import { Command, FormACConfigs, Room } from "./KeyDetail.d";
import { ACFanSpeed, ACMode, ACStatus, ActingMode, CommandStatus, OperationalMode } from "../../types";
import {
  NodeSubType,
  NodeType,
  useCreateAcSettingCommandsMutation,
  useGetNodeSlotsQuery,
  useGetShowHeatingModeQuery,
} from "../../../../pacts/app-webcore/hasura-webcore.graphql";
import errorHandler from "../../../../errorHandler";
import {
  formatTemp,
  getACText,
  getDoorStatusText,
  getOccupancyText,
  getUnitDisplayText,
  MAX_TEMP,
  MIN_TEMP,
} from "../../key.helper";
import StatusText from "../../../../components/StatusText";
import usePrecoolCountDown from "../../hooks/use-precool-countdown";
import styles from "./Room.module.scss";
import { parseDate } from "../../../../utils/date";
import { RoomConfig } from "../KeyConfiguration/KeyConfiguration.d";

const RoomComponent = (props: { room: Room }) => {
  const { room } = props;
  const screen = useBreakpoint();

  const [initialConfigs, setInitialConfigs] = useState<FormACConfigs>();
  const [hvacPower, setHvacPower] = useState<boolean>(false);
  const [hasPendingCommand, setHasPendingCommand] = useState<boolean>(false);
  const [hasChange, setHasChange] = useState<boolean>(false);
  const [ACPrecoolExpiredTime, setACPrecoolExpiredTime] = useState<Moment>();
  const [ACPrecoolStartedAt, setACPrecoolStartedAt] = useState<Moment | null>();
  const [ACPrecoolStatus, setACPrecoolStatus] = useState<PreCoolStatus | null | undefined>();
  const [pendingCommands, setPendingCommands] = useState<Command[]>([]);
  const { locationId } = useParams<{ locationId: string }>();
  const [showHeatingMode, setShowHeatingMode] = useState<boolean>();

  const { currentTime, getRemainingTime } = usePrecoolCountDown();
  const [isPfcTypes, setIsPfcTypes] = useState<boolean>(false);
  const [has2Pfc, setHas2Pfc] = useState<boolean>(false);

  const [form] = Form.useForm<FormACConfigs>();

  const updateConfigs = (configs: FormACConfigs) => {
    form.setFieldsValue(configs);
    setHvacPower(configs.hvacPower!);
    setHasChange(false);
  };

  useGetNodeSlotsQuery({
    variables: {
      roomId: room.positionId,
    },
    onCompleted: ({ sensorflow_node_slots: data }) => {
      data.forEach((nodeSlot) => {
        if (nodeSlot.nodeSubType === NodeSubType.Aircon_2pfc || nodeSlot.nodeSubType === NodeSubType.Aircon_4pfc) {
          setIsPfcTypes(true);
        }
        if (nodeSlot.nodeSubType === NodeSubType.Aircon_2pfc) {
          setHas2Pfc(true);
        }
      });
    },
    onError: errorHandler.handleError,
  });

  useGetShowHeatingModeQuery({
    variables: {
      locationId,
    },
    onCompleted: ({ locationMetadata }) => {
      setShowHeatingMode(locationMetadata?.showHeatingMode ?? false);
    },
    onError: errorHandler.handleError,
  });

  const getLastPendingCommand = () => {
    return room.lastACSettingCommands.find(
      (p) => p.status === CommandStatus.PENDING || p.status === CommandStatus.ACCEPTED
    );
  };

  const handlePendingCommandChanged = (newCommands: Command[]) => {
    if (pendingCommands.find((p) => p.status === CommandStatus.PENDING || p.status === CommandStatus.ACCEPTED)) {
      if (newCommands.find((c) => c.status === CommandStatus.SUCCESS)) {
        message.success("HVAC Settings set");
      }
      if (newCommands.find((c) => c.status === CommandStatus.FAILED)) {
        message.error("HVAC Settings failed to set");
      }
    }
    setPendingCommands(room.lastACSettingCommands);
  };

  useEffect(() => {
    // If there is pending command then default values for form should be desired values
    const lastAcCommand = getLastPendingCommand();
    const configs: FormACConfigs = {
      hvacMode: lastAcCommand?.toACMode ?? room.nodeMeasurements[0]?.acMode ?? ACMode.Fan,
      hvacPower: (lastAcCommand?.toACStatus ?? room.nodeMeasurements[0]?.acStatus ?? ACStatus.Off) === ACStatus.On,
      hvacFanSpeed: lastAcCommand?.toFanSpeed ?? room.nodeMeasurements[0]?.fanSpeed ?? ACFanSpeed.Auto,
      hvacSetpoint: lastAcCommand?.toACSetPoint ?? room.nodeMeasurements[0]?.acSetPoint ?? 26,
    };
    setInitialConfigs(configs);
    setHasPendingCommand(!!getLastPendingCommand());
    handlePendingCommandChanged(room.lastACSettingCommands);
    updateConfigs(configs);

    setACPrecoolStatus(room.precoolCommands[0]?.status as PreCoolStatus);

    let acPrecoolStartedAt: Moment | null = null;
    if (room.precoolCommands[0]?.startedAt) {
      acPrecoolStartedAt = parseDate(room.precoolCommands[0].startedAt);
      setACPrecoolStartedAt(moment(room.precoolCommands[0].startedAt));
    } else if (room.precoolCommands[0]?.creationDate) {
      acPrecoolStartedAt = parseDate(room.precoolCommands[0]?.creationDate);
    }
    const acPrecoolExpiredTime =
      room.precoolCommands[0]?.totalMinutes && acPrecoolStartedAt
        ? acPrecoolStartedAt.add(room.precoolCommands[0]?.totalMinutes, "minutes")
        : undefined;
    setACPrecoolExpiredTime(acPrecoolExpiredTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room]);

  const resetConfig = () => {
    if (initialConfigs) {
      updateConfigs(initialConfigs);
    }
  };

  const [createAcSettingCommandsMutation] = useCreateAcSettingCommandsMutation({
    onCompleted: () => {
      setHasPendingCommand(true);
    },
    onError: errorHandler.handleError,
  });

  const createACSettingCommand = () => {
    const configs = form.getFieldsValue();
    let currentConfig = {};
    if (initialConfigs) {
      currentConfig = {
        fromACStatus: initialConfigs.hvacPower ? ACStatus.On : ACStatus.Off,
        fromACSetPoint: initialConfigs.hvacSetpoint,
        fromACMode: initialConfigs.hvacMode,
        fromFanSpeed: initialConfigs.hvacFanSpeed,
      };
    }
    setHasChange(false);
    setHasPendingCommand(true);
    createAcSettingCommandsMutation({
      variables: {
        acSettingCommands: [
          {
            toACStatus: configs.hvacPower ? ACStatus.On : ACStatus.Off,
            toACSetPoint: configs.hvacSetpoint,
            toACMode: configs.hvacMode,
            toFanSpeed: configs.hvacFanSpeed,
            ...currentConfig,
            positionId: room.positionId,
            source: "DASHBOARD",
            status: "PENDING",
          },
        ],
      },
    });
  };

  const hasNodeType = (type: NodeType) => {
    return !!room.slotMappings.find(({ nodeType }) => nodeType === type);
  };

  const isActingMode = useMemo(() => {
    return (room?.positionConfiguration?.[0] as RoomConfig | undefined)?.actingMode === ActingMode.TwoPFC;
  }, [room]);

  const onFormChange = () => {
    if (!initialConfigs) return false;
    const currentConfigs = form.getFieldsValue();
    const hasDiff =
      currentConfigs.hvacPower !== initialConfigs.hvacPower ||
      currentConfigs.hvacSetpoint !== initialConfigs.hvacSetpoint ||
      currentConfigs.hvacMode !== initialConfigs.hvacMode ||
      currentConfigs.hvacFanSpeed !== initialConfigs.hvacFanSpeed;
    setHasChange(hasDiff);
  };

  const hvacChange = (val: boolean) => {
    setHvacPower(val);
    onFormChange();
  };

  const displayPrefixIcon = (icon: ReactNode, text: string) => {
    return (
      <div>
        <span className="d-inline-block prefixed-icon text-center">{icon}</span>
        <span>{text}</span>
      </div>
    );
  };

  return (
    <div>
      <Row gutter={50}>
        <Col lg={8} xs={24}>
          <ul
            className={classnames(
              "d-flex justify-content-center flex-column list-style-none p-none bg-lily-white p-xl",
              styles.height30
            )}
          >
            <li className="d-flex justify-content-between pb-s w-100">
              <span>{displayPrefixIcon(<TemperatureIcon />, "Ambient Temperature")}</span>
              <span>
                <StatusText
                  isNotAvailable={!hasNodeType(NodeType.Aircon)}
                  isNotUpdated={get(room, "nodeMeasurements[0].ambientTemperature") === null}
                >
                  {getUnitDisplayText(get(room, "nodeMeasurements[0].ambientTemperature"), "°C", "Not Updated")}
                </StatusText>
              </span>
            </li>
            <li className="d-flex justify-content-between w-100">
              <span>{displayPrefixIcon(<DryIcon />, "Ambient Humidity")}</span>
              <span>
                <StatusText
                  isNotAvailable={!hasNodeType(NodeType.Aircon)}
                  isNotUpdated={get(room, "nodeMeasurements[0].ambientHumidity") === null}
                >
                  {getUnitDisplayText(get(room, "nodeMeasurements[0].ambientHumidity"), "% Rh", "Not Updated")}
                </StatusText>
                {!isNil(get(room, "acSettingCommands[0].creationDate")) &&
                  hasNodeType(NodeType.Aircon) &&
                  screen.desktopUp && <div className="fs-xs">&nbsp;</div>}
              </span>
            </li>
          </ul>
          <div className={styles.bottomCards}>
            <ul className="d-flex align-items-center list-style-none p-none bg-lily-white p-xl">
              <li className="d-flex justify-content-between w-100">
                <span>{displayPrefixIcon(<PreCoolIcon />, "Pre-set")}</span>
                <StatusText
                  isNotAvailable={!hasNodeType(NodeType.Aircon)}
                  isNotUpdated={!isNil(ACPrecoolExpiredTime) && ACPrecoolExpiredTime!.isAfter(currentTime)}
                >
                  {getRemainingTime(ACPrecoolExpiredTime, ACPrecoolStatus, currentTime, ACPrecoolStartedAt, "None")}
                </StatusText>
              </li>
            </ul>
            <ul className="d-flex align-items-center list-style-none p-none bg-lily-white p-xl">
              <li className="d-flex justify-content-between w-100">
                <span>{displayPrefixIcon(<UserIcon />, "Occupancy Status")}</span>
                <span>
                  <StatusText
                    isNotAvailable={!hasNodeType(NodeType.Occupancy)}
                    isNotUpdated={get(room, "nodeMeasurements[0].occupancyState") === null}
                  >
                    {getOccupancyText(get(room, "nodeMeasurements[0].occupancyState"))}
                  </StatusText>
                </span>
              </li>
            </ul>
            <ul className="d-flex align-items-center list-style-none p-none bg-lily-white p-xl mb-none">
              <li className="d-flex justify-content-between w-100">
                <span>{displayPrefixIcon(<DoorCloseIcon />, "Door Status")}</span>
                <span>
                  <StatusText
                    isNotAvailable={!hasNodeType(NodeType.Door)}
                    isNotUpdated={isNil(get(room, "nodeMeasurements[0].door"))}
                  >
                    {getDoorStatusText(get(room, "nodeMeasurements[0].door"))}
                  </StatusText>
                </span>
              </li>
            </ul>
          </div>
        </Col>
        {screen.mobileAndTabletOnly && <div className="pt-m w-100" />}
        <Col lg={8} xs={24}>
          <ul
            className={classnames(
              "d-flex justify-content-center flex-column list-style-none p-none bg-lily-white p-xl",
              styles.height30
            )}
          >
            <li className="d-flex justify-content-between w-100">
              <span>{displayPrefixIcon(<CoolIcon />, "HVAC Status")}</span>
              <span>
                <StatusText
                  isNotAvailable={!hasNodeType(NodeType.Aircon)}
                  isNotUpdated={isNil(get(room, "nodeMeasurements[0].acStatus"))}
                >
                  {getACText(get(room, "nodeMeasurements[0].acStatus"))}
                </StatusText>
              </span>
            </li>
          </ul>
          <div className={classnames("p-xl bg-lily-white", styles.acForm)}>
            <Form layout="vertical" form={form} name={room.positionId}>
              <Form.Item className="form-input-space-between">
                <span className="pr-m">HVAC Power</span>
                <Form.Item noStyle name="hvacPower" valuePropName="checked">
                  <Switch
                    checkedChildren={<PowerOnIcon />}
                    unCheckedChildren={<PowerOffIcon />}
                    onChange={hvacChange}
                    disabled={!hasNodeType(NodeType.Aircon)}
                  />
                </Form.Item>
              </Form.Item>
              <div>
                <Form.Item className="form-input-space-between">
                  <span>HVAC Setpoint</span>
                  <Form.Item noStyle name="hvacSetpoint">
                    <Select
                      className={classnames({ "w-25": screen.desktopUp, "w-35": !screen.desktopUp })}
                      disabled={!hasNodeType(NodeType.Aircon) || !hvacPower}
                      onChange={onFormChange}
                    >
                      {range(
                        room?.positionConfiguration?.[0]?.minTemp ?? MIN_TEMP,
                        (room?.positionConfiguration?.[0]?.maxTemp ?? MAX_TEMP) + 1
                      ).map((n) => (
                        <Select.Option key={n} value={n}>
                          {formatTemp(n, "")}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form.Item>
                <Form.Item className="form-input-space-between">
                  <span>HVAC Mode</span>
                  <Form.Item noStyle name="hvacMode">
                    <Select
                      className={classnames({ "w-35": screen.desktopUp, "w-50": !screen.desktopUp })}
                      disabled={!hasNodeType(NodeType.Aircon) || !hvacPower}
                      onChange={onFormChange}
                    >
                      {!isPfcTypes && (
                        <Select.Option value={ACMode.Auto}>
                          <AutoModeIcon /> Auto
                        </Select.Option>
                      )}

                      {(!isActingMode ||
                        (isActingMode &&
                          (room?.positionConfiguration?.[0] as RoomConfig | undefined)?.operationalMode !==
                            OperationalMode.Heating)) && (
                        <Select.Option value={ACMode.Cool}>
                          <CoolIcon /> Cool
                        </Select.Option>
                      )}

                      {!isPfcTypes && (
                        <Select.Option value={ACMode.Dry}>
                          <DryIcon /> Dry
                        </Select.Option>
                      )}

                      <Select.Option value={ACMode.Fan}>
                        <FanIcon /> Fan
                      </Select.Option>

                      {showHeatingMode &&
                        !has2Pfc &&
                        (!isActingMode ||
                          (isActingMode &&
                            (room?.positionConfiguration?.[0] as RoomConfig | undefined)?.operationalMode !==
                              OperationalMode.Cooling)) && (
                          <Select.Option value={ACMode.Heat}>
                            <SunIcon /> Heat
                          </Select.Option>
                        )}
                    </Select>
                  </Form.Item>
                </Form.Item>
                <Form.Item className="form-input-space-between">
                  <span>HVAC Fan Speed</span>
                  <Form.Item noStyle name="hvacFanSpeed">
                    <Select
                      className={classnames({ "w-35": screen.desktopUp, "w-50": !screen.desktopUp })}
                      disabled={!hasNodeType(NodeType.Aircon) || !hvacPower}
                      onChange={onFormChange}
                    >
                      <Select.Option value={ACFanSpeed.Auto}>Auto</Select.Option>
                      <Select.Option value={ACFanSpeed.Low}>Low</Select.Option>
                      <Select.Option value={ACFanSpeed.Medium}>Medium</Select.Option>
                      <Select.Option value={ACFanSpeed.High}>High</Select.Option>
                    </Select>
                  </Form.Item>
                </Form.Item>
              </div>
              <div className="mt-xl d-flex justify-content-center">
                <Button
                  type="default"
                  className="mr-m"
                  onClick={resetConfig}
                  disabled={!hasNodeType(NodeType.Aircon) || !hasChange}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  loading={hasPendingCommand}
                  htmlType="submit"
                  onClick={createACSettingCommand}
                  disabled={!hasNodeType(NodeType.Aircon) || !hasChange}
                >
                  Update
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RoomComponent;
