import React, { useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Breadcrumb, Tabs } from "antd";
import { useErrorHandler } from "errorHandler";
import Loading from "components/Loading";
import KeyListPage from "pages/Key/KeyList/KeyList";
import ContractorKeyList from "pages/Key/ContractorKeyList/KeyList";
import CustomerKeyListPage from "pages/Key/CustomerKeyList/KeyList";
import KeyCategoryListPage from "pages/KeyCategory/KeyCategoryList";
import { useLocationMetadataLazyQuery, useLocationQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import { Permission } from "pacts/permission";
import GatewayList from "pages/Gateway/GatewayList/GatewayList";
import NodeList from "pages/Node/NodeList/NodeList";
import Infrastructures from "pages/Infrastructures/Infrastructures";
import { ContractorNodeListKey } from "pages/Node/ContractorNodeListKey";
import useRoleAndPermission from "hooks/use-role-and-permission";
import useMenuNavigation from "hooks/use-menu-navigation";
import { SpareNodes } from "pages/SpareNodes";
import "./LocationDetails.scss";
import { useFeatureFlag } from "hooks/use-feature-flag";
import { Jobs } from "pages/Jobs";
import useBreakpoint from "hooks/use-breakpoint";
import { Groups } from "pages/Groups";
import { WarningUseMobileOnly } from "components/WarningUseMobileOnly";
import InfrastructuresV2 from "pages/Infrastructures/InfrastructuresV2";
import InsightsDashboardList from "pages/Analytics/Analytics";
import useSensorflowLocation from "hooks/use-sensorflow-location";
import KeyConfigurationList from "../../Key/KeyConfigurationList/KeyConfigurationList";
import EnergyConsumption from "../../EnergyConsumption/EnergyConsumption";

import { ActiveTab } from "./LocationDetails.d";

const keyListWrapper = (roleAndPermission: ReturnType<typeof useRoleAndPermission>) => {
  if (roleAndPermission.isContractor()) return <ContractorKeyList />;
  if (roleAndPermission.isCustomer()) return <CustomerKeyListPage />;
  return <KeyListPage />;
};

const canAccessNodeSpareSetList = (roleAndPermission: ReturnType<typeof useRoleAndPermission>) => {
  return roleAndPermission.isWS() || roleAndPermission.isContractor() || roleAndPermission.isPC();
};

const getDefaultActiveTab = (roleAndPermission: ReturnType<typeof useRoleAndPermission>) => {
  if (roleAndPermission.isContractor()) return ActiveTab.JOBS;
  return ActiveTab.KEYS;
};

const LocationDetails = () => {
  const history = useHistory();
  const errorHandler = useErrorHandler();
  const breakpoint = useBreakpoint();
  const { locationId, activeTab } = useParams<{ locationId: string; activeTab: string }>();
  const [locationName, setLocationName] = useState<string>();
  const [showEnergyConsumption, setShowEnergyConsumption] = useState<boolean>();
  const roleAndPermission = useRoleAndPermission();
  const menuNavigation = useMenuNavigation();
  const { updateCompressorLocationEnabled } = useFeatureFlag();
  const { location } = useSensorflowLocation();
  const rebuildLocationMenu = (id: string, name: string) => {
    if (id && name) menuNavigation.setupLocationMenu([{ id, locationName: name }]);
  };

  const defaultActiveTab = useMemo(() => getDefaultActiveTab(roleAndPermission), [roleAndPermission]);

  const { loading } = useLocationQuery({
    variables: { locationId },
    onCompleted: (data) => {
      setLocationName(data.location?.locationName);
      rebuildLocationMenu(locationId, data.location?.locationName!);
    },
    onError: errorHandler.handleErrorWithStatusCode,
  });

  const [locationMetadataLazyQuery] = useLocationMetadataLazyQuery({
    variables: { locationId },
    onCompleted: (data) => {
      setShowEnergyConsumption(!!data.locationMetadata?.showEnergyConsumption);
    },
    onError: errorHandler.handleErrorWithStatusCode,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (roleAndPermission.isCustomer()) locationMetadataLazyQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldShowWarningForContractorOnDesktop = useMemo<boolean>(() => {
    return roleAndPermission.isContractor() && !breakpoint.mobileAndTabletOnly;
  }, [roleAndPermission, breakpoint.mobileAndTabletOnly]);

  const handleTabChange = (activeKey: string) => {
    history.push(`/locations/${locationId}/${activeKey}`);
  };

  const tabConfigs = [
    {
      name: "Keys",
      key: ActiveTab.KEYS,
      component: keyListWrapper(roleAndPermission),
      isVisible: !roleAndPermission.isContractor(),
    },
    {
      name: "Categories",
      key: ActiveTab.CATEGORIES,
      component: <KeyCategoryListPage locationName={locationName as string} />,
      isVisible: roleAndPermission.canPerform(Permission.LOCATION_CREATE),
    },
    {
      name: "Jobs",
      key: ActiveTab.JOBS,
      component: <Jobs />,
      isVisible: breakpoint.mobileAndTabletOnly && roleAndPermission.isContractor(),
    },

    {
      name: "Gateways",
      key: ActiveTab.GATEWAYS,
      component: shouldShowWarningForContractorOnDesktop ? (
        <WarningUseMobileOnly />
      ) : (
        <GatewayList locationName={locationName as string} />
      ),
      isVisible: !roleAndPermission.isCustomer(),
    },
    {
      name: "Configuration",
      key: ActiveTab.CONFIGURATION,
      component: <KeyConfigurationList />,
      isVisible: roleAndPermission.isCustomer(),
    },
    {
      name: "Nodes",
      key: ActiveTab.NODES,
      component: roleAndPermission.isContractor() ? <ContractorNodeListKey /> : <NodeList />,
      isVisible: roleAndPermission.canPerform(Permission.NODES_LIST) && !roleAndPermission.isContractor(),
    },
    {
      name: "Infrastructures",
      key: ActiveTab.INFRASTRUCTURES,
      component: shouldShowWarningForContractorOnDesktop ? (
        <WarningUseMobileOnly />
      ) : (
        <>
          {roleAndPermission.isPC() && updateCompressorLocationEnabled ? (
            <InfrastructuresV2 locationName={locationName} />
          ) : (
            <Infrastructures locationName={locationName as string} />
          )}
        </>
      ),
      isVisible: roleAndPermission.isPC(),
    },
    {
      name: "Energy Consumption",
      key: ActiveTab.ENERGY_CONSUMPTION,
      component: <EnergyConsumption />,
      isVisible: showEnergyConsumption,
    },
    {
      name: "Spare Nodes",
      key: ActiveTab.SPARE_NODES,
      component: <SpareNodes locationId={locationId} locationName={locationName} />,
      isVisible: canAccessNodeSpareSetList(roleAndPermission),
    },
    {
      name: "Groups",
      key: ActiveTab.GROUPS,
      component: <Groups locationId={locationId} />,
      isVisible: roleAndPermission.isPC(),
    },
    {
      name: "Analytics",
      key: ActiveTab.ANALYTICS,
      component: <InsightsDashboardList locationId={locationId} />,
      isVisible:
        (roleAndPermission.isPC() ||
          (roleAndPermission.isCustomer() && (location?.enabledFeatures ?? []).find((f) => f === "DomoEmbed"))) &&
        !breakpoint.mobileOnly,
    },
  ];

  const tabs = tabConfigs.filter(({ isVisible }) => isVisible);

  return loading ? (
    <Loading />
  ) : (
    <div>
      <Breadcrumb className="header-bread-crumb">
        <Breadcrumb.Item>
          <Link to="/locations">Location</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{locationName}</Breadcrumb.Item>
      </Breadcrumb>

      <Tabs className="header-tab-bar" activeKey={activeTab ?? defaultActiveTab} onChange={handleTabChange}>
        {tabs.map((tab) => (
          <Tabs.TabPane tab={tab.name} key={tab.key}>
            {tab.component}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default LocationDetails;
