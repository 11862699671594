import moment from "moment";
import React from "react";
import { FILTER_DROPDOWN_COUNT_HIDDEN_VALUE } from "components/FilterDropdown/FilterDropdown.d";
import { DoorStatus, OccupancyState } from "pages/Key/types";
import {
  ActivityLogsSubscription,
  FilterOption,
  FilterOptionType,
} from "../../../../pacts/app-webcore/hasura-webcore.graphql";

export type Room = NonNullable<ActivityLogsSubscription["key"]>["rooms"][number] & RoomConfig;

export interface CsvExportButtonProps {
  room: Room;
  loading: boolean;
}

export type ActivityLog = {
  id: string;
  user: string | null | undefined;
  message: React.ReactNode;
  plainTextMessage: string;
  logGroupType: LogGroupTypes;
  logType: LogTypes | string | number;
  previousValue?: string | number | null;
  currentValue: string | number;
  time: moment;
};

export enum LogGroupTypes {
  HVAC = "hvac",
  AUTOMATION_MODE = "automationMode",
  CONFIGURATIONS = "configurations",
  DOOR = "door",
  OCCUPANCY = "occupancy",
}

export enum LogTypes {
  OCC_NIGHT = "occNight",
  AC_NIGHT = "acNight",
  OCC_TIMEOUT_DAY_MINS = "occTimeoutDayMins",
  OCC_TIMEOUT_NIGHT_MINS = "occTimeoutNightMins",
  DOOR_SENSOR_TIMEOUT = "doorSensorTimeoutMins",
  UNOCCUPIED_TEMP = "unoccupiedTemp",
  SETPOINT_LIMIT = "setpointLimit",
  AC_STATUS = "acStatus",
  AC_MODE = "acMode",
  AC_SETPOINT = "acSetpoint",
  AC_FANSPEED = "acFanspeed",
  AC_MODEL = "acModel",
  INSTALLATION_MODE = "installationMode",
}

export type ActionFilterInput = {
  automationMode?: Array<string>;
  configurations?: Array<string>;
  hvac?: Array<string>;
  door?: Array<string>;
  occupancy?: Array<string>;
};

export const filterOptions: FilterOption[] = [
  {
    field: LogGroupTypes.CONFIGURATIONS,
    value: LogTypes.OCC_NIGHT,
    label: "Active Hours",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "CONFIGURATIONS",
    type: FilterOptionType.Checkbox,
  },
  {
    field: LogGroupTypes.CONFIGURATIONS,
    value: LogTypes.AC_NIGHT,
    label: "Automation Hours",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "CONFIGURATIONS",
    type: FilterOptionType.Checkbox,
  },
  {
    field: LogGroupTypes.CONFIGURATIONS,
    value: LogTypes.OCC_TIMEOUT_DAY_MINS,
    label: "Occupancy Timeout During Active Hours",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "CONFIGURATIONS",
    type: FilterOptionType.Checkbox,
  },
  {
    field: LogGroupTypes.CONFIGURATIONS,
    value: LogTypes.OCC_TIMEOUT_NIGHT_MINS,
    label: "Occupancy Timeout Outside Active Hours",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "CONFIGURATIONS",
    type: FilterOptionType.Checkbox,
  },
  {
    field: LogGroupTypes.CONFIGURATIONS,
    value: LogTypes.SETPOINT_LIMIT,
    label: "Setpoint Limit",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "CONFIGURATIONS",
    type: FilterOptionType.Checkbox,
  },
  {
    field: LogGroupTypes.CONFIGURATIONS,
    value: LogTypes.UNOCCUPIED_TEMP,
    label: "Unoccupied Setpoint",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "CONFIGURATIONS",
    type: FilterOptionType.Checkbox,
  },
  {
    field: LogGroupTypes.HVAC,
    value: LogTypes.AC_STATUS,
    label: "HVAC Power",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "HVAC",
    type: FilterOptionType.Checkbox,
  },
  {
    field: LogGroupTypes.HVAC,
    value: LogTypes.AC_MODE,
    label: "HVAC Mode",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "HVAC",
    type: FilterOptionType.Checkbox,
  },
  {
    field: LogGroupTypes.HVAC,
    value: LogTypes.AC_SETPOINT,
    label: "HVAC Setpoint",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "HVAC",
    type: FilterOptionType.Checkbox,
  },
  {
    field: LogGroupTypes.HVAC,
    value: LogTypes.AC_FANSPEED,
    label: "HVAC Fan Speed",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "HVAC",
    type: FilterOptionType.Checkbox,
  },
  {
    field: LogGroupTypes.DOOR,
    value: DoorStatus.Open.toString(),
    label: "Open",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "DOOR",
    type: FilterOptionType.Checkbox,
  },
  {
    field: LogGroupTypes.DOOR,
    value: DoorStatus.Close.toString(),
    label: "Closed",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "DOOR",
    type: FilterOptionType.Checkbox,
  },
  {
    field: LogGroupTypes.OCCUPANCY,
    value: OccupancyState.Occupied.toString(),
    label: "Occupied",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "OCCUPANCY",
    type: FilterOptionType.Checkbox,
  },
  {
    field: LogGroupTypes.OCCUPANCY,
    value: OccupancyState.Unoccupied.toString(),
    label: "Vacant",
    count: FILTER_DROPDOWN_COUNT_HIDDEN_VALUE,
    groupName: "OCCUPANCY",
    type: FilterOptionType.Checkbox,
  },
];

export type LogsToCSV = {
  user: string | null | undefined;
  message: string;
  time: string;
};

export enum ChangedByType {
  GUEST_INTERACTION = "GUEST_INTERACTION",
  AUTOMATION = "AUTOMATION",
}
