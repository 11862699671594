export default {
  positionId: "333010e2-9fc9-4b15-9902-c6d937f61d00",
  positionName: "1000",
  location: {
    positions: (_root: any, _args: any) => {
      if (_args.where?.positionName?._gt) {
        return [
          {
            positionId: "333010e2-9fc9-4b15-9902-c6d937f61d04",
            positionName: "1001",
          },
        ];
      }
      if (_args.where?.positionName?._lt) {
        return [
          {
            positionId: "333010e2-9fc9-4b15-9902-c6d937f61d98",
            positionName: "Key 1",
          },
        ];
      }
    },
  },
  rooms: [
    {
      positionId: "e0f7d982-e527-11e9-ad3b-a45e60d743d3",
      positionName: "Bedroom 1",
      slotMappings: [
        {
          nodeType: "door",
          nodeMacId: "FFFF14B4570DF168",
        },
        {
          nodeType: "occupancy",
          nodeMacId: "FFFF14B4570DF167",
        },
        {
          nodeType: "aircon",
          nodeMacId: "FFFF14B4570DF169",
        },
      ],
      acSettingCommands: (root: any, args: any) => {
        if (args.where?.status?._eq === "SUCCESS")
          return [
            {
              source: "DASHBOARD",
              createdByUser: {
                userId: "auth0|606bcda29a8597006879ebd4",
                name: "John Doe",
              },
              fromACStatus: 1,
              toACStatus: 0,
              fromACSetPoint: 14,
              toACSetPoint: 18,
              fromACMode: 1,
              toACMode: 2,
              fromFanSpeed: 3,
              toFanSpeed: 1,
              creationDate: "2021-05-21T04:19:22.958012+00:00",
              __typename: "sensorflow_ac_setting_commands",
            },
            {
              source: "DASHBOARD",
              createdByUser: {
                userId: "auth0|606bcda29a8597006879ebd4",
                name: "John Doe",
              },
              fromACStatus: 0,
              toACStatus: 1,
              fromACSetPoint: null,
              toACSetPoint: null,
              fromACMode: null,
              toACMode: null,
              fromFanSpeed: null,
              toFanSpeed: null,
              creationDate: "2021-05-21T03:46:45.62679+00:00",
            },
          ];
        return [];
      },
      positionConfiguration: [
        {
          automationMode: "NONE",
          isDeviatedFromDefaultConfig: true,
          creationDate: "2021-05-10T13:54:22.346834+00:00",
          occNightStart: "20:00",
          occNightEnd: "03:00",
          occTimeoutNightMins: 45,
          occTimeoutDayMins: 30,
          occupancySensitivity: null,
          doorSensorTimeoutMins: 25,
          softstartWaitTime: null,
          hysteresisOffDegrees: null,
          hysteresisOnDegrees: null,
          hideSetpoint: true,
          unoccupiedTemp: null,
          minTemp: 18,
          maxTemp: 27,
          acNightEnd: "10:00",
          acNightStart: "08:00",
          actuationTimeoutSeconds: 300,
          bridgeAdaptorSetting: null,
          desiredEmonSwitchPosition: null,
          freezeProtectionTemp: 18,
          recordType: "CURRENT",
          specialInstalltionMode: false,
          heatingHideSetpoint: false,
          heatingMaxTemp: 30,
          heatingMinTemp: 19,
          heatingUnoccupiedTemp: null,
          modifiedByUser: {
            userId: "auth0|606bcda29a8597006879ebd4",
            name: "John Doe",
          },
          expiredAt: null,
          enableDoorSensor: false,
          actingMode: "default",
          operationalMode: "cooling",
        },
        {
          automationMode: "SMARTSAVE",
          isDeviatedFromDefaultConfig: false,
          creationDate: "2021-05-21T10:29:04.627007+00:00",
          occNightStart: "20:00",
          occNightEnd: "23:00",
          occTimeoutNightMins: 15,
          occTimeoutDayMins: 10,
          occupancySensitivity: null,
          doorSensorTimeoutMins: 25,
          softstartWaitTime: null,
          hysteresisOffDegrees: null,
          hysteresisOnDegrees: null,
          hideSetpoint: true,
          unoccupiedTemp: null,
          minTemp: 16,
          maxTemp: 22,
          acNightEnd: null,
          acNightStart: null,
          actuationTimeoutSeconds: null,
          bridgeAdaptorSetting: null,
          desiredEmonSwitchPosition: null,
          freezeProtectionTemp: null,
          recordType: "DEFAULT",
          specialInstalltionMode: false,
          heatingHideSetpoint: false,
          heatingMaxTemp: 30,
          heatingMinTemp: 19,
          heatingUnoccupiedTemp: null,
          modifiedByUser: {
            userId: "auth0|606bcda29a8597006879ebd4",
            name: "John Doe",
          },
          expiredAt: null,
          enableDoorSensor: false,
          actingMode: "default",
          operationalMode: "cooling",
        },
      ],
      nodeMeasurements: [
        {
          ambientTemperature: 15,
          ambientHumidity: 20,
          door: 1,
          occupancyState: 0,
          acStatus: 1,
          acMode: 2,
          acSetPoint: 18,
          fanSpeed: 1,
          __typename: "sensorflow_node_measurement_view",
        },
      ],
    },
    {
      positionId: "e10a0832-e527-11e9-ad3b-a45e60d743d3",
      positionName: "Bedroom 2",
      slotMappings: [
        {
          nodeType: "door",
          nodeMacId: "FFFF14B4570DF168",
        },
        {
          nodeType: "occupancy",
          nodeMacId: "FFFF14B4570DF167",
        },
        {
          nodeType: "aircon",
          nodeMacId: "FFFF14B4570DF169",
        },
      ],
      positionConfiguration: [
        {
          automationMode: "NONE",
          isDeviatedFromDefaultConfig: false,
          creationDate: "2021-05-10T13:54:22.346834+00:00",
          occNightStart: "1",
          occNightEnd: null,
          occTimeoutNightMins: null,
          occTimeoutDayMins: null,
          occupancySensitivity: null,
          doorSensorTimeoutMins: null,
          softstartWaitTime: null,
          hysteresisOffDegrees: null,
          hysteresisOnDegrees: null,
          hideSetpoint: true,
          unoccupiedTemp: null,
          minTemp: null,
          maxTemp: null,
          acNightEnd: null,
          acNightStart: null,
          actuationTimeoutSeconds: 300,
          bridgeAdaptorSetting: null,
          desiredEmonSwitchPosition: null,
          recordType: "DEFAULT",
          specialInstalltionMode: false,
          heatingHideSetpoint: false,
          heatingMaxTemp: 30,
          heatingMinTemp: 19,
          heatingUnoccupiedTemp: null,
          modifiedByUser: {
            userId: "auth0|606bcda29a8597006879ebd4",
            name: "John Doe",
          },
          expiredAt: null,
          enableDoorSensor: false,
          actingMode: "default",
          operationalMode: "cooling",
        },
        {
          automationMode: "NONE",
          isDeviatedFromDefaultConfig: false,
          creationDate: "2021-05-10T13:54:22.346834+00:00",
          occNightStart: "1",
          occNightEnd: null,
          occTimeoutNightMins: null,
          occTimeoutDayMins: null,
          occupancySensitivity: null,
          doorSensorTimeoutMins: null,
          softstartWaitTime: null,
          hysteresisOffDegrees: null,
          hysteresisOnDegrees: null,
          hideSetpoint: true,
          unoccupiedTemp: null,
          minTemp: null,
          maxTemp: null,
          acNightEnd: null,
          acNightStart: null,
          actuationTimeoutSeconds: 300,
          bridgeAdaptorSetting: null,
          desiredEmonSwitchPosition: null,
          recordType: "CURRENT",
          specialInstalltionMode: false,
          heatingHideSetpoint: false,
          heatingMaxTemp: 30,
          heatingMinTemp: 19,
          heatingUnoccupiedTemp: null,
          modifiedByUser: {
            userId: "auth0|606bcda29a8597006879ebd4",
            name: "John Doe",
          },
          expiredAt: null,
          enableDoorSensor: false,
          actingMode: "default",
          operationalMode: "cooling",
        },
      ],
      nodeMeasurements: [
        {
          ambientTemperature: 15,
          ambientHumidity: 20,
          door: 1,
          occupancyState: 0,
          acStatus: 0,
          acMode: 2,
          acSetPoint: 10,
          fanSpeed: 1,
          __typename: "sensorflow_node_measurement_view",
        },
      ],
      acSettingCommands: (root: any, args: any) => {
        if (args.where?.status?._eq === "SUCCESS")
          return [
            {
              source: "DASHBOARD",
              createdByUser: {
                userId: "auth0|606bcda29a8597006879ebd4",
                name: "John Doe",
              },
              fromACStatus: 1,
              toACStatus: 0,
              fromACSetPoint: 14,
              toACSetPoint: 18,
              fromACMode: 1,
              toACMode: 2,
              fromFanSpeed: 3,
              toFanSpeed: 1,
              creationDate: "2021-05-21T04:19:22.958012+00:00",
              __typename: "sensorflow_ac_setting_commands",
            },
            {
              source: "DASHBOARD",
              createdByUser: {
                userId: "auth0|606bcda29a8597006879ebd4",
                name: "John Doe",
              },
              fromACStatus: 0,
              toACStatus: 1,
              fromACSetPoint: null,
              toACSetPoint: null,
              fromACMode: null,
              toACMode: null,
              fromFanSpeed: null,
              toFanSpeed: null,
              creationDate: "2021-05-21T03:46:45.62679+00:00",
            },
          ];
        return [
          {
            status: "PENDING",
            fromACStatus: 0,
            toACStatus: 1,
            fromACSetPoint: 10,
            toACSetPoint: 15,
            fromACMode: 0,
            toACMode: 2,
            fromFanSpeed: 1,
            toFanSpeed: 3,
          },
        ];
      },
    },
    {
      positionId: "e10a0832-e527-11e9-ad3b-a45e60d743d4",
      positionName: "Bedroom 3",
      slotMappings: [
        {
          nodeType: "door",
          nodeMacId: "FFFF14B4570DF168",
        },
      ],
      positionConfiguration: [
        {
          automationMode: "NONE",
          isDeviatedFromDefaultConfig: true,
          creationDate: "2021-05-10T13:54:22.346834+00:00",
          occNightStart: "20:00",
          occNightEnd: "03:00",
          occTimeoutNightMins: 45,
          occTimeoutDayMins: 30,
          occupancySensitivity: null,
          doorSensorTimeoutMins: 25,
          softstartWaitTime: null,
          hysteresisOffDegrees: null,
          hysteresisOnDegrees: null,
          hideSetpoint: true,
          unoccupiedTemp: null,
          minTemp: 18,
          maxTemp: 27,
          acNightEnd: "10:00",
          acNightStart: "08:00",
          actuationTimeoutSeconds: 300,
          bridgeAdaptorSetting: null,
          desiredEmonSwitchPosition: null,
          freezeProtectionTemp: 18,
          recordType: "CURRENT",
          specialInstalltionMode: false,
          heatingHideSetpoint: false,
          heatingMaxTemp: 30,
          heatingMinTemp: 19,
          heatingUnoccupiedTemp: null,
          modifiedByUser: {
            userId: "auth0|606bcda29a8597006879ebd4",
            name: "John Doe",
          },
          expiredAt: null,
          enableDoorSensor: false,
          actingMode: "default",
          operationalMode: "cooling",
        },
        {
          automationMode: "NONE",
          isDeviatedFromDefaultConfig: false,
          creationDate: "2021-05-21T10:29:04.627007+00:00",
          occNightStart: "20:00",
          occNightEnd: "23:00",
          occTimeoutNightMins: 15,
          occTimeoutDayMins: 10,
          occupancySensitivity: null,
          doorSensorTimeoutMins: 25,
          softstartWaitTime: null,
          hysteresisOffDegrees: null,
          hysteresisOnDegrees: null,
          hideSetpoint: true,
          unoccupiedTemp: null,
          minTemp: 16,
          maxTemp: 22,
          acNightEnd: null,
          acNightStart: null,
          actuationTimeoutSeconds: null,
          bridgeAdaptorSetting: null,
          desiredEmonSwitchPosition: null,
          freezeProtectionTemp: null,
          recordType: "DEFAULT",
          specialInstalltionMode: false,
          heatingHideSetpoint: false,
          heatingMaxTemp: 30,
          heatingMinTemp: 19,
          heatingUnoccupiedTemp: null,
          modifiedByUser: {
            userId: "auth0|606bcda29a8597006879ebd4",
            name: "John Doe",
          },
          expiredAt: null,
          enableDoorSensor: false,
          actingMode: "default",
          operationalMode: "cooling",
        },
      ],
      nodeMeasurements: [
        {
          ambientTemperature: null,
          ambientHumidity: null,
          door: null,
          occupancyState: null,
          acStatus: null,
          acMode: null,
          acSetPoint: null,
          fanSpeed: null,
          __typename: "sensorflow_node_measurement_view",
        },
      ],
      acSettingCommands: [],
    },
  ],
};
