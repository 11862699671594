import { Affix, Col, Pagination, Row, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import Table from "components/Table/Table";
import useBreakpoint from "hooks/use-breakpoint";
import keyBy from "lodash/keyBy";
import { useJobEntryDataSubscription, useWorkInfoSubscription } from "pacts/app-webcore/hasura-webcore.graphql";
import React, { useCallback, useContext, useMemo } from "react";
import { TaskStatus } from "pages/KeyEntryJobChecklist";
import { InstallationModeContext } from "contexts/InstallationMode/installation-mode-context";
import { PositionFunction, PositionType } from "types/InstallationMode.d";
import { JobCard } from "../JobCard";
import { KeyAndTask } from "../Jobs.d";
import { JobsTableProps } from "./JobsTable.d";

const POSITION_FUNC: Record<string, PositionFunction> = {
  [PositionType.COMPRESSOR]: PositionFunction.COMPRESSOR,
  [PositionType.METER_POSITION]: PositionFunction.COMPRESSOR,
  [PositionType.KEY]: PositionFunction.AUTOSET,
  [PositionType.GATEWAY]: PositionFunction.GATEWAY,
};

const columns: ColumnsType<KeyAndTask> = [
  {
    title: "Key Name",
    dataIndex: "keyName",
    key: "keyName",
  },
  {
    title: "Category Name",
    dataIndex: "categoryName",
    key: "categoryName",
  },
];

const JobsTable: React.FC<JobsTableProps> = ({
  data,
  handleTableChange,
  loading,
  pagination,
  error,
  locationId,
  isGroupView,
  currentFilter,
}) => {
  const screen = useBreakpoint();
  const { currentKeyEntryOfPositions = [] } = useContext(InstallationModeContext);

  const jobEntryFilter = useMemo(() => {
    const listEntryIds = data.map((key) => {
      return { keyPositionId: { _eq: key.keyId } };
    });
    return { _or: listEntryIds };
  }, [data]);

  const { loading: jobEntryLoading, data: jobEntryData } = useJobEntryDataSubscription({
    skip: !data.length,
    variables: { where: jobEntryFilter },
  });

  const { loading: workInfoLoading, data: workInfoData } = useWorkInfoSubscription({
    skip: !data.length,
    variables: {
      where: {
        keyId: { _in: data?.map((task) => task.keyId) },
        status: { _eq: TaskStatus.PENDING },
      },
    },
  });

  const jobEntryDataMap = useMemo(() => {
    return keyBy(jobEntryData?.jobEntries, (jobEntry) =>
      JSON.stringify({
        keyPositionId: jobEntry.keyPositionId,
        positionFunction: jobEntry.positionFunction,
      })
    );
  }, [jobEntryData]);

  const getJobEntryData = useCallback(
    (keyPositionId: string, positionFunction: PositionFunction) => {
      return jobEntryDataMap[JSON.stringify({ keyPositionId, positionFunction })];
    },
    [jobEntryDataMap]
  );

  return (
    <Row gutter={[16, 16]}>
      {screen.desktopUp ? (
        <Col span={24}>
          <Table
            columns={columns}
            handleTableChange={handleTableChange}
            pagination={pagination}
            tableData={data}
            loading={loading || jobEntryLoading || workInfoLoading}
            error={error}
          />
        </Col>
      ) : (
        <>
          {data
            .filter((key) => {
              if (isGroupView) return true;
              return (
                workInfoData?.tasksWithKeys?.findIndex((w) => w.keyId === key.keyId) !== -1 ||
                currentKeyEntryOfPositions?.findIndex((entryData) => entryData.keyPositionId === key.keyId) !== -1 ||
                key.isNew
              );
            })
            .map((key) => (
              <Col span={24} key={key.keyId}>
                <JobCard
                  currentFilter={currentFilter}
                  keyId={key.keyId}
                  totalRooms={key.totalRooms}
                  totalKeys={key.totalKeys}
                  keyName={key.keyName}
                  categoryName={key.categoryName}
                  group={key.group}
                  assigneeName={getJobEntryData(key.keyId, POSITION_FUNC[key.possitionType || ""])?.user?.name}
                  assigneeId={getJobEntryData(key.keyId, POSITION_FUNC[key.possitionType || ""])?.user?.userId}
                  locationId={locationId}
                  loading={loading}
                  isGroupView={isGroupView}
                  status={getJobEntryData(key.keyId, POSITION_FUNC[key.possitionType || ""])?.status}
                  statusStartedAt={getJobEntryData(key.keyId, POSITION_FUNC[key.possitionType || ""])?.startedAt}
                  statusEndedAt={getJobEntryData(key.keyId, POSITION_FUNC[key.possitionType || ""])?.endedAt}
                  possitionType={key.possitionType || ""}
                  comments={getJobEntryData(key.keyId, POSITION_FUNC[key.possitionType || ""])?.comment ?? undefined}
                  remainKeyEntries={key.remainKeyEntries}
                  taskDueDate={key.taskDueDate}
                />
              </Col>
            ))}
          <Affix offsetBottom={0} className="w-100 px-s">
            <Row gutter={[16, 16]} wrap justify={pagination.total >= 50 ? "center" : "space-between"} align="middle">
              <Col span="auto">
                <Typography.Text>
                  {pagination.current}-{pagination.pageSize} / {pagination.total} Jobs
                </Typography.Text>
              </Col>
              <Col span="auto">
                <Pagination
                  current={pagination.current}
                  total={pagination.total}
                  pageSize={pagination.pageSize}
                  onChange={(page, pageSize) => handleTableChange({ current: page, pageSize, total: pagination.total })}
                />
              </Col>
            </Row>
          </Affix>
        </>
      )}
    </Row>
  );
};

export default JobsTable;
