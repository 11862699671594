import React, { useCallback, useEffect, useState } from "react";
import { AutoComplete, Input } from "antd";
import debounce from "lodash/debounce";
import errorHandler from "errorHandler";
import { useOrganisationsForAutocompleteLazyQuery } from "pacts/app-webcore/hasura-webcore.graphql";
import { OrganizationAutoCompleteProps } from "./OrganizationAutoComplete.d";

const OrganizationAutoComplete = ({ handleChangedValue, value: currentValue }: OrganizationAutoCompleteProps) => {
  const [value, setValue] = useState(currentValue || "");
  const [options, setOptions] = useState<Array<{ value: string }>>([]);

  const [organisationsForAutocomplete, { loading: searchingOrganization }] = useOrganisationsForAutocompleteLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const filteredOptions = data.organisations.organisations.map((org) => ({ value: org.name }));
      setOptions(filteredOptions);
    },
    onError: errorHandler.handleError,
  });

  useEffect(() => {
    organisationsForAutocomplete({
      variables: {
        filter: {
          name: "",
        },
      },
    });
  }, [organisationsForAutocomplete]);

  useEffect(() => {
    setValue(currentValue || "");
  }, [currentValue]);

  const handleSearch = useCallback(
    () =>
      debounce((searchInput) => {
        organisationsForAutocomplete({
          variables: {
            filter: {
              name: searchInput,
            },
          },
        });
      }, 500),
    [organisationsForAutocomplete]
  );

  const onBlur = () => {
    handleChangedValue?.(value);
  };

  return (
    <AutoComplete
      className="w-100"
      options={options}
      onSearch={handleSearch}
      onChange={setValue}
      onBlur={onBlur}
      onSelect={handleChangedValue}
      value={value}
      notFoundContent="Organization is not found"
    >
      <Input.Search size="large" placeholder="Type to search organization" loading={searchingOrganization} />
    </AutoComplete>
  );
};

export default OrganizationAutoComplete;
