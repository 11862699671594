enum ActiveTab {
  CATEGORIES = "categories",
  KEYS = "keys",
  JOBS = "jobs",
  GATEWAYS = "gateways",
  CONFIGURATION = "configuration",
  NODES = "nodes",
  INFRASTRUCTURES = "infrastructures",
  ENERGY_CONSUMPTION = "energy-consumption",
  SPARE_NODES = "spare-nodes",
  GROUPS = "groups",
  ANALYTICS = "analytics",
}

// eslint-disable-next-line import/prefer-default-export
export { ActiveTab };
